<template>
  <div>
    <!-- Row Start -->
    <div class="row">
      <div class="col-12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Branch</h3>
            </div>
            <div class="card-toolbar">
              <b-button variant="outline-primary" v-b-modal.add-branch-modal
                >Add Branch</b-button
              >
            </div>
          </div>

          <div class="card-body body-fluid">
            <b-table striped hover :items="branches" :fields="fields">
              <template #cell(actions)="row">
                <b-button @click="ShowEditModal(row.item.id)" variant="primary"
                  >Edit</b-button
                >
                <b-button
                  @click="deletebranch(row.item.id)"
                  class="ml-2"
                  variant="danger"
                  >Delete</b-button
                >
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
    <!-- Row End -->
    <!-- Add Modal -->
    <!-- <form class="form" novalidate="novalidate" id="add-branch-form"> -->
    <b-modal
      id="add-branch-modal"
      ref="modal"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      title="Add Branch"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-12">
            <b-form-group
              label="Branch Name"
              label-for="name-input"
              invalid-feedback="Name is required"
              :state="nameState"
            >
              <b-form-input
                id="name-input"
                v-model="form.name"
                :state="nameState"
                required
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-12">
            <label>Country</label>
            <b-form-select
              :options="countries"
              value-field="id"
              text-field="name"
              v-model="form.country_id"
              size="md"
              class=""
              required
            ></b-form-select>
          </div>
          <div class="col-md-12">
            <b-form-checkbox v-model="form.isHeadBranch"
              >Is Head branch</b-form-checkbox
            >
          </div>
        </div>
      </form>
    </b-modal>

    <!-- Edit Modal -->
    <b-modal
      id="edit-branch-modal"
      ref="modal"
      @hidden="resetModal"
      @ok="handleUpdateOk"
      title="Edit Branch"
    >
      <form ref="editform" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-12">
            <b-form-group
              label="Branch Name"
              label-for="name-input-1"
              invalid-feedback="Name is required"
              :state="nameState"
            >
              <b-form-input
                id="name-input-1"
                v-model="form.name"
                :state="nameState"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-checkbox v-model="form.isHeadBranch"
              >Is Head branch</b-form-checkbox
            >
          </div>
        </div>
      </form>
    </b-modal>
    <!-- </form> -->
    <loading :active.sync="isLoading"></loading>
  </div>
</template>

<script>
// import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import { mapState } from "vuex";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// import KTUtil from "@/assets/js/components/util";
export default {
  data() {
    return {
      branches: [],
      isLoading: false,
      fields: [
        {
          key: "name",
          label: "Name",
        },
        {
          key: "isHeadBranch",
          label: "Is Head branch",
          formatter: (value) => {
            if (value == 1) {
              return "Yes";
            } else {
              return "No";
            }
          },
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
      form: {
        name: "",
        isHeadBranch: false,
        is_active: true,
        country_id: 0,
        id: 0,
      },
      nameState: null,
      submittedNames: [],
      countries: [],
      states: [],
    };
  },
  components: {
    Loading,
  },
  created() {
    this.getAllBranches();
    this.getAllCountries();
    // this.getStates(1);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Settings",
      },
      {
        title: "Branch",
      },
    ]);
  },
  methods: {
    getAllCountries() {
      ApiService.get("getcountries")
        .then(({ data }) => {
          this.countries = data;
          this.form.country_id = this.countries.length
            ? this.countries[0].id
            : null;
        })
        .catch(() => {});
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    checkEditFormValidity() {
      const valid = this.$refs.editform.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      this.form.name = "";
      this.form.isHeadBranch = false;
      this.nameState = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleUpdateOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.updatebranch();
    },
    handleSubmit() {
      
      if (!this.checkFormValidity()) {
        return;
      }
      this.isLoading = true;
      ApiService.post("branch", this.form)
        .then(() => {
          this.getAllBranches();
          this.getAllCountries();
          this.$nextTick(() => {
            this.$bvModal.hide("add-branch-modal");
            this.makeToastVariant(
              "success",
              "Branch added successfully",
              "Success"
            );
          });
        })
        .catch(() => {
          alert("error");
          this.isLoading = false;
        });
    },
    updatebranch() {
      if (!this.checkEditFormValidity()) {
        return;
      }
      this.isLoading = true;
      ApiService.update("branch", this.form.id, this.form)
        .then(() => {
          this.getAllBranches();
          this.$nextTick(() => {
            this.$bvModal.hide("edit-branch-modal");
            this.makeToastVariant(
              "info",
              "Branch updated successfully",
              "Success"
            );
          });
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    getAllBranches() {
      this.isLoading = true;
      ApiService.get("branch")
        .then(({ data }) => {
          this.branches = data;
          this.isLoading = false;
        })
        .catch(() => {});
    },
    ShowEditModal(id) {
      this.isLoading = true;
      ApiService.get("branch", id)
        .then(({ data }) => {
          this.form = data;
          if (this.form.isHeadBranch == 1) {
            this.form.isHeadBranch = true;
          }
          this.$bvModal.show("edit-branch-modal");
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    deletebranch(id) {
      this.isLoading = true;
      ApiService.delete("branch", id)
        .then(() => {
          this.makeToastVariant(
            "warning",
            "Branch deleted successfully",
            "Success"
          );
          this.getAllBranches();
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    makeToastVariant(variant = null, text, title) {
      this.$bvToast.toast(text, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
  },
};
</script>

<style scoped>
#__BVID__84 {
  margin-bottom: auto;
}
</style>
